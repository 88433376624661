<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:items-loading="itemsLoading"
						:filters="filters"
						@setFilters="setFilters"
					/>

					<ItemsTableContainer
						ref="ItemsTableContainer"
						:items-loading="itemsLoading"
						:items-saving="itemsSaving"
						:table-data="itemsList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler } from '@/mixins';
import { USER_TYPES } from '@/constants/global';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		ItemsTableContainer: () => import('../../components/ItemsTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.users.fetchedMeta,
			filters: state => state.users.filters,
			itemsLoading: state => state.users.isLoading,
			itemsSaving: state => state.users.isSaving,
			itemsList: state => state.users.itemsList,

			authUser: state => state.auth.authUser
		}),

		itemsName() {
			return {
				one: 'Пользователь',
				mult: 'Пользователи'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'full_name', label: 'Имя', sortable: true },
					{ prop: 'email', label: 'Email', sortable: true },
					{ prop: 'phone_number', label: 'Телефон' },
					{
						prop: 'role.display_name',
						label: 'Роль',
						sortable: true,
						meta: { emptyText: ' ', sortBy: 'type' }
					}
				],
				operations: {
					edit: true,
					delete: this.authUser.role.type === USER_TYPES.ADMIN
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			show_overlay: 'show_overlay',

			fetch_items: 'users/fetch_users',
			delete_item: 'users/delete_user',

			set_items: 'users/set_users',
			set_filters: 'users/set_users_filters'
		})
	}
};
</script>
